const trJSON = {
    'previous': 'Önceki',
    'next': 'Sonraki',
    'submit' : 'Gönder',
    'copyright' : 'Tüm hakları saklıdır.',
    'file_upload_button_tooltip' : 'Dosya yükle',
    'uploadedFiles' : 'Yüklenen dosyalar',
    'plsFillFieldsAlert' : 'Lütfen * ile işaretlenmiş tüm alanları doldurun',
    'yes' : 'Evet',
    'no' : 'Hayır',
    'other' : 'Diğer',
    'general' : 'Genel',
    'question' : 'Soru',
    'detail' : 'Detay',
    'Main Option' : 'Ana Seçenek',
    'Sub Option' : 'Alt Seçenek',
    'Electronics & HVAC Repairs': 'Elektronik ve HVAC Tamirleri',
    'Food and Beverage': 'Yiyecek ve İçecek',
    'Room and Bathroom Amenities': 'Oda ve Banyo Olanakları',
    'Bathroom & Plumbing Repairs': 'Banyo ve Tesisat Tamirleri',
    'Information Services': 'Bilgi Hizmetleri',
    'General Services': 'Genel Hizmetler',
    'General Repairs & Maintenance': 'Genel Tamir ve Bakım',
    'Air Conditioning Issue': 'Klima Arızası',
    'Heating System Malfunction': 'Isıtma Sistemi Arızası',
    'Wi-Fi Connectivity Problem': 'Wi-Fi Bağlantı Sorunu',
    'Minibar Malfunction': 'Minibar Arızası',
    'In-Room Phone Line Issue': 'Odadaki Telefon Hattı Sorunu',
    'Electrical Outlet Issue': 'Elektrik Prizi Sorunu',
    'Burnt-out Bulb': 'Yanmış Ampul',
    'Light Switch Issue': 'Işık Anahtarı Sorunu',
    'Smoke Detector Malfunction': 'Duman Dedektörü Arızası',
    'Safe Box Electricity': 'Kasa Elektriği',
    'Room Service Order': 'Oda Servisi Talebi',
    'Special Dietary Request': 'Özel Diyet Yemek Talebi',
    'Breakfast Services and Timing': 'Kahvaltı Servisi ve Zamanlama Bilgisi',
    'Allergen Information Inquiry': 'Alerjen Bilgisi Sorgulama',
    'Coffee and Tea Provisions': 'Kahve ve Çay İkmali',
    'Late-Night Snack Options': 'Geç Saat Atıştırmalık Seçenekleri',
    'A la Carte Reservation Assistance': 'A la Carte Rezervasyon Talebi',
    'Mini-Fridge Refill': 'Minibar Dolumu',
    'Bedding and Linens Request': 'Yatak Takımı ve Çarşaf Talebi',
    'Extra Pillow Request': 'Ekstra Yastık Talebi',
    'Prayer Mat Request': 'Seccade Talebi',
    'Family & Childcare Items Request': 'Aile ve Çocuk Bakım Ürünleri Talebi',
    'Crib Request': 'Beşik Talebi',
    'Extra Hanger Request': 'Ekstra Askı Talebi',
    'Extra Bed Request': 'Ekstra Yatak Talebi',
    'Room Key Issue': 'Oda Anahtarı Sorunu',
    'Phone Charger/Adapter/Cable Request': 'Telefon Şarj Cihazı/Adaptör/Kablo Talebi',
    'Medical Supply Request': 'Tıbbi Malzeme Talebi',
    'Towel Request': 'Havlu Talebi',
    'Hairdryer Request': 'Saç Kurutma Makinesi Talebi',
    'Bathrobe Request': 'Bornoz Talebi',
    'Slipper Request': 'Terlik Talebi',
    'Shampoo, Conditioner Refill Request': 'Şampuan, Saç Kremi Dolum Talebi',
    'Toothbrush Request': 'Diş Fırçası Talebi',
    'Cotton Request': 'Pamuk Talebi',
    'Shaving Kit Request': 'Tıraş Seti Talebi',
    'Toilet Malfunction': 'Tuvalet Arızası',
    'Water Pressure or Temperature Issue': 'Su Basıncı veya Sıcaklık Sorunu',
    'Faucet Malfunction': 'Musluk Arızası',
    'Towel Rack Issue': 'Havlu Askısı Sorunu',
    'Shower Header Issue': 'Duş Başlığı Sorunu',
    'Check-in, Check-out Inquiry': 'Check-in Check-out Bilgisi Sorgusu',
    'Lost & Found Inquiry': 'Kayıp Eşya Sorgusu',
    'Directions and Local Area Information': 'Yol Tarifi ve Yerel Alan Bilgisi',
    'General Room Information': 'Genel Oda Bilgisi',
    'Extra Services, Events or Activity  Inquiry': 'Ekstra Hizmetler, Etkinlikler veya Aktiviteler Sorgusu',
    'Hotel Facilities Inquiry': 'Otel Tesisleri Sorgusu',
    'Nearby Pharmacy and Hospital Inquiry': 'Yakın Eczane ve Hastane Bilgisi',
    'Wake-up Call Request': 'Uyandırma Servisi',
    'Laundry Request': 'Çamaşır Yıkama Hizmeti',
    'Housekeeping Request': 'Kat Hizmetleri Talebi',
    'Package or Mail Request': 'Paket veya Posta Talebi',
    'Transportation or Taxi Booking Assistance': 'Ulaşım veya Taksi Yardımı',
    'Pest Control': 'Haşere Kontrolü',
    'Event or Activity Booking': 'Etkinlik veya Aktivite Rezervasyonu',
    'Late Check-out Request': 'Geç Check-out Talebi',
    'Special Guest Requests': 'Özel Misafir Talepleri',
    'Carpentry Issue': 'Marangozluk Sorunu',
    'Flooring & Tiling': 'Döşeme ve Fayans',
    'Structural Repair': 'Yapısal Onarım',
    'Wallpaper issue': 'Duvar Kağıdı Sorunu',
    'Paint Issue': 'Boya Sorunu',
    'Foul Odour': 'Kötü Koku',
    'Elevator Malfunction': 'Asansör Arızası',
    'Door Malfunction': 'Kapı Arızası',
    'Sanitation Issue': 'Hijyen Sorunu',
    'Other': 'Diğer',
    'Silence Pool Outdoor (Main Pool)': 'Sessiz Havuz',
    'Jacuzzi Pool': 'Jakuzi Havuzu',
    'Kids Pool with Octopus': 'Ahtapotlu Çocuk Havuzu',
    'Indoor Pool Indoor': 'Kapalı Havuz',
    'Indoor Kids Pool Indoor': 'Kapalı Çocuk Havuzu',
    'Activity Pool Outdoor': 'Activity Pool Outdoor',
    'Aqua Pool Outdoor': 'Aqua Pool Outdoor',
    'Kids Pool with Elephant': 'Filli Çocuk Havuzu',
    'Royal Salon': 'Royal Salon',
    'Konferans Salon': 'Konferans Salon',
    'Committee 4': 'Komite 4',
    'Marina Wedding Banquet': 'Marina Düğün Alanı',
    'Panorama Wedding Banquet': 'Panorama Düğün Alanı',
    'Efes Salon': 'Efes Salon',
    'Committee 3': 'Komite 3',
    'Committee 5': 'Komite 5',
    'Committee 1': 'Komite 1',
    'Committee 2': 'Komite 2',
    'Indoor Pool': 'Kapalı Havuz',
    'Kids Pool Outdoor': 'Açık Çocuk Havuzu',
    'Cottage Room Pool': 'Cottage Room Pool',
    'Main Pool(Outdoor)': 'Ana Havuz (Açık)',
    'Sundeck Room Pool (Outdoor)': 'Sundeck Room Havuz (Açık)',
    'Ballroom': 'Balo Salonu',
    'Boardroom': 'Boardroom',
    'Fuaye': 'Foyer',
    'Piccione': 'Piccione',
    'Street': 'Sokak',
    'Tannery Garden': 'Tannery Bahçesi',
    'Tannery X I': 'Tabakhane X I',
    'Tannery X II': 'Tabakhane X II',
    'Stone House': 'Taşhane',
    'Meeting Room 1': 'Toplantı Odası 1',
    'Meeting Room 2': 'Toplantı Odası 2',
    'Meeting Room 3': 'Toplantı Odası 3',
    'Board Room Toplantı Odası': 'Board Room Toplantı Odası',
    'Business Center': 'İş Merkezi',
    'GIO Rooftop Lounge Bar': 'GIO Çatı Katı Lounge Bar',
    'GIO Rooftop Restaurant': 'GIO Çatı Restaurant',
    'Linnet Pattiserie Cafe': 'Linnet Pastane Cafe',
    'Breakfast Lounge': 'Breakfast Lounge',
    'Stone Tavern': 'Taş Meyhane',
    'Vintage': 'Vintage',
    'Vineria': 'Vineria',
    'Tannery beer garden': 'Tannery beer garden',
    'Public': 'Public',
    'Sparrow Restaurant': 'Sparrow Restaurant',
    'Tanen': 'Tanen',
    'Tannery': 'Tabakhane',
    'Pool Snack': 'Pool Snack',
    'Pool Snack Bar': 'Pool Snack Bar',
    'linnet bar': 'Linnet bar',
    '1st Floor Toilet Female': '1. Kat Tuvalet Bayan',
    '1st Floor Restroom Male': '1. Kat Tuvalet Erkek',
    '1st Floor Disabled Toilet': '1. Kat Engelli Tuvalet',
    'Pool Mr. Wc': 'Havuz Bay Wc',
    'Pool Ladies Wc': 'Havuz Bayan Wc',
    'Pool Shower': 'Havuz Duş',
    'Lobby Restroom Women': 'Lobi Tuvalet Kadın',
    'Lobby Restroom Male': 'Lobi Tuvalet Erkek',
    'Lobby Disabled Toilet': 'Lobi Engelli Tuvalet',
    'Gio Toilet Woman': 'Gio Tuvalet Kadın',
    'Gio Toilet Male': 'Gio Tuvalet Erkek',
    'Old Town Tanneries Toilet Women': 'Old Town Tanneries Tuvalet Kadın',
    'Old Town Tanneries Tuvalet Erkek': 'Old Town Tanneries Tuvalet Erkek',
    'Dressing Room Women': 'Soyunma Odası Kadın',
    'Dressing Room Male': 'Soyunma Odası Erkek',
    'Toilet Woman': 'Tuvalet Kadın',
    'Toilet Male': 'Tuvalet Erkek',
    'Toilet Disabled': 'Tuvalet Engelli',
    'Tuvalet Personnel': 'Tuvalet Personel',
    'Corridors / Massage Rooms Corridor': 'Koridorlar / Masaj Odaları Koridoru',
    'Basement Personel Tuvalet ve Duş Kadın': 'Basement Personel Tuvalet ve Duş Kadın',
    'Basement Personel Tuvalet ve Duş Erkek': 'Basement Personel Tuvalet ve Duş Erkek',
    'Indoor Parking': 'Kapalı Otopark',
    'Open Parking Lot': 'Açık Otopark',
    'Reception and Lobby': 'Resepsiyon ve Lobi',
    'Lobby - Guest Relation': 'Lobby - Guest Relation',
    'Lobby-Winter Garden': 'Lobi-Kış Bahçesi',
    'Lobby-Library': 'Lobi-Kütüphane',
    'Lobby-Hotel Entrance': 'Lobi-Otel Giriş',
    'Lobby-Gio Entrance': 'Lobi-Gio Giriş',
    'Breakfast Room Staircase': 'Kahvaltı Salonu Merdiven',
    'Guest Elevator': 'Misafir Asansör',
    'Guest Elevator Car Parking Lift': 'Misafir Asansör Otopark Asansör',
    'Security Gate': 'Güvenlik Kapısı',
    'Meeting Area': 'Toplantı Alanı',
    'General Directorate Office': 'Genel Müdürlük Ofisi',
    'Secretariat Office': 'Sekreterlik Ofisi',
    'Accounting': 'Muhasebe',
    'FB Office': 'FB Ofisi',
    'Sales Marketing Office': 'Satış Pazarlama Ofisi',
    'Technical Office': 'Teknik Ofis',
    'Front Office': 'Ön Büro Ofisi',
    'Luggage Room': 'Bagaj Odası',
    'Housekeeping Office': 'Housekeeping Ofisi',
    'Basement Warehouse': 'Basement Depo',
    'Steward Warehouse': 'Steward Depo',
    'Water Tank': 'Su Deposu',
    'Meydanci Warehouse': 'Meydanci Deposu',
    'Beverage Storage': 'İçecek Deposu',
    'Dry Garbage': 'Kuru Çöp',
    'Cold Garbage': 'Soğuk Çöp',
    'Pool Upper Floor Warehouse': 'Havuz Üst Kat Depo',
    'Technical Warehouse': 'Teknik Depo',
    'Mechanical Center': 'Mekanik Merkez',
    'Electric Main Panel': 'Elektrik Ana Pano',
    'Indoor Pool Machine Room': 'Kapalı Havuz Makine Dairesi',
    'Machinery Department (Next to Parking Lot)': 'Makine Dairesi (Otopark Yanı)',
    'Spa Heat Recovery Device': 'Spa Isı Geri Kazanım Cihazı',
    'Weak Current Room': 'Zayıf Akım Odası',
    'Main kitchen': 'Ana Mutfak',
    'Linnet Kitchen': 'Linnet Mutfak',
    'Breakfast Room Kitchen': 'Kahvaltı Salonu Mutfak',
    'Picchione Kitchen': 'Picchione Mutfak',
    'Gio Kitchen': 'Gio Mutfak',
    'Gio Kitchen - Cold Storage': 'Gio Mutfak - Soğuk Depo',
    'Vegetable cabinet': 'Sebze Dolabı',
    'Main Kitchen Butcher Shop Cabinets': 'Ana Mutfak Kasaphane Dolaplar',
    'Patisserie Cabinets': 'Pastane Dolaplar',
    'Dining Hall Bain Marie': 'Yemekhane Benmari',
    'Dining Hall Air Conditioner': 'Yemekhane Klima',
    'Main Kitchen Dishroom': 'Ana Mutfak Bulaşıkhane',
    'Main Kitchen Water Expenses': 'Ana Mutfak Su Giderleri',
    'Breakfast Room Focus and Grill': 'Kahvaltı Salonu Odak ve Izgara',
    'Breakfast Room Vertical Cabinet': 'Kahvaltı Salonu Dikey Dolap',
    'Breakfast Room Ventilation': 'Kahvaltı Salonu Havalandırma',
    'Gio Kitchen Cold Air': 'Gio Mutfak Soğuk Hava',
    'Gio Kitchen Dishroom': 'Gio Mutfak Bulaşıkhane',
    'Stone Tavern Kitchen': 'Taş Meyhane Mutfak',
    'Stone Tavern Dishwashing': 'Taş Meyhane Bulaşıkhane',
    'Spa Reception': 'Spa Resepsiyon',
    'Sauna': 'Sauna',
    'Hamam': 'Hamam',
    'Pouch Room': 'Kese Odası',
    'Steam Room': 'Buhar Odası',
    'Massage Rooms': 'Masaj Odaları',
    'Plates Room': 'Plates Odası',
    'Fitness': 'Fitness',
    'Laundry': 'Çamaşırhane',
    'Staff Trainning Hall': 'Personel Eğitim Salonu',
    'Efes Meeting Hall': 'Efes Toplantı Salonu',
    'Royal Hall Backstage': 'Royal Salon Kulis',
    'Pergamon': 'Pergamon',
    'Miletus': 'Miletus',
    'Priene Salon': 'Priene Salon',
    'Ephesus Salon': 'Ephesus Salon',
    'Conferance Saloon (Pergamon+Miletus)': 'Konferans Salonu (Pergamon+Miletus)',
    'Conferance Saloon (Miletus+Priene)': 'Konferans Salonu (Miletus+Priene)',
    'Conferance Saloon (Pergamon+Miletus+Priene)': 'Konferans Salonu (Pergamon+Miletus+Priene)',
    'Silence Children Pool': 'Sessiz Çocuk Havuzu',
    'Ephesus': 'Ephesus',
    'Claros': 'Claros',
    'Miletos': 'Miletos',
    'Anaia': 'Anaia',
    'Priene': 'Priene',
    'Nysa': 'Nysa',
    'Alinda': 'Alinda',
    'Didyma': 'Didyma',
    'Efes Restaurant': 'Efes Restoran',
    'Green Restaurant': 'Green Restoren',
    'Marina Restaurant': 'Marina Restoran',
    'Teras Restaurant': 'Teras Restoran',
    'Panorama Restaurant': 'Panorama Restoran',
    'Efe Cafe & Patisserie': 'Efe Cafe & Pastane',
    'Pine Street Waffle House': 'Pine Street Waffle House',
    'Fuaye Cafe': 'Fuaye Cafe',
    'Amphıtheater Bar': 'Anfi Tiyatro Bar',
    'Beach Bar': 'Beach Bar',
    'Fuaye Bar': 'Fuaye Bar',
    'Lescalier Bar': 'Lescalier Bar',
    'Marina Bar/Kitchen': 'Marina Bar/Mutfak',
    'Sunset Bar': 'Sunset Bar',
    'Pool Bar': 'Pool Bar',
    'Terrace Restaurant Short Bar': 'Teras Restoran Kısa Bar',
    'Terrace Restaurant Long Bar': 'Teras Restoran Uzun Bar',
    'Panaroma Bar/Kıtchen': 'Panaroma Bar/Mutfak',
    'Tennis Court Bar': 'Tenis Kort Bar',
    'Sail Bar': 'Yelken Bar',
    'Olea Bar': 'Olea Bar',
    'Sunset Terrace Bar': 'Sunset Teras Bar',
    'Aqua Bay Wc': 'Aqua Bay Wc',
    'Aqua Ladies Wc': 'Aqua Bayan Wc',
    'Aqua Shower Cabins': 'Aqua Duş Kabinleri',
    'Efes Restaurant Bay Wc': 'Efes Restoran Bay Wc',
    'Efes Restaurant Ladies Wc': 'Efes Restoran Bayan Wc',
    'General Directorate Wc': 'Genel Müdürlük Wc',
    'Hammam-Sauna Bay Wc': 'Hamam-Sauna Bay Wc',
    'Turkish Bath-Sauna Ladıes Wc': 'Hamam-Sauna Bayan Wc',
    'Marina Bay Wc': 'Marina Bay Wc',
    'Marina Ladies Wc': 'Marina Bayan Wc',
    'Male Wc Under Kitchen': 'Mutfak Altı Bay Wc',
    'Female Wc Under Kitchen': 'Mutfak Altı Bayan Wc',
    'Behind the Front Office Mr. Wc': 'Ön Büro Arkası Bay Wc',
    'Behind the Front Office Female WC': 'Ön Büro Arkası Bayan Wc',
    'Panaroma Bay Wc': 'Panaroma Bay Wc',
    'Panaroma Lady Wc': 'Panaroma Bayan Wc',
    'Royal Bay Wc': 'Royal Bay Wc',
    'Rolay Woman Wc': 'Royal Bayan Wc',
    'Tennis Court Bay Wc': 'Tenis Kort Bay Wc',
    'Tennis Court Ladıes Wc': 'Tenis Kort Bayan Wc',
    'Turbulence Sub Bay Wc': 'Turbulans Altı Bay Wc',
    'Female Wc Under Turbulence': 'Turbulans Altı Bayan Wc',
    '1. Lobby Bay Wc': '1. Lobby Bay Wc',
    '1. Lobby Female Wc': '1. Lobby Bayan Wc',
    '2. Lobby Bay Wc': '2. Lobby Bay Wc',
    '2.Lobby Ladies Wc': '2. Lobby Bayan Wc',
    '3. Lobby Bay Wc': '3. Lobby Bay Wc',
    '3. Lobby Female Wc': '3. Lobby Bayan Wc',
    'Sail Bar Bay Wc': 'Yelken Bar Bay Wc',
    'Sail Bar Ladies Wc': 'Yelken Bar Bayan Wc',
    'Outdoor Pool Shower Cabins': 'Açık Havuz Duş Kabinleri',
    'Beach Changing Booth': 'Beach Soyunma Kabini',
    'Cold Room Number 1 (Meat Deep)': '1 Nolu Soğuk Oda(Et Deep)',
    'Cold Room Number 2 (Meat And Fısh)': '2 Nolu Soğuk Oda(Et Balık)',
    'Cold Room 3 (Meat Coz.)': '3 Nolu Soğuk Oda(Et Coz.)',
    'Cold Room 4 (Vegetable)': '4 Nolu Soğuk Oda(Sebze)',
    'Cold Room 5 (Fruit)': '5 Nolu Soğuk Oda(Meyve)',
    'Cold Room 6 (Watermelon)': '6 Nolu Soğuk Oda(Karpız)',
    'Cold Room 7 (Hot)': '7 Nolu Soğuk Oda(Sıcak)',
    'Cold Room Number 8 (Cold Appetızer)': '8 Nolu Soğuk Oda(Soğuk Meze)',
    'Cold Room 9 (Cold)': '9 Nolu Soğuk Oda(Soğuk)',
    'Cold Room 10 (Breakfast)': '10 Nolu Soğuk Oda(Kahvaltı)',
    'Cold Room 11 (Patisserie)': '11 Nolu Soğuk Oda(Pastane)',
    'Cold Room 12 (Ambr Etdeep)': '12 Nolu Soğuk Oda(Ambr Etdeep)',
    'Cold Room 13 (Fish Deep)': '13 Nolu Soğuk Oda(Balık Deep)',
    'No. 14 Cold Room (Warehouse Cold Room)': '14 Nolu Soğuk Oda(Ambar S.Oda)',
    'Cold Room Number 15 (Warehouse Cold Room)': '15 Nolu Soğuk Oda(Ambar S.Oda)',
    'Cold Room 16 (Chicken Deep)': '16 Nolu Soğuk Oda(Tavuk Deep)',
    'Cold Room 17 (Pastanedeep)': '17 Nolu Soğuk Oda(Pastanedeep)',
    'Cold Room 18 (Terrace Lıquor)': '18 Nolu Soğuk Oda(Teras İçki)',
    'Cold Room 19 (Cop)': '19 Nolu Soğuk Oda(Cop)',
    'Cold Room 20 (Staff Meter)': '20 Nolu Soğuk Oda(Personel Mt)',
    'Cold Room 21 (Panorama)': '21 Nolu Soğuk Oda(Panaroma)',
    'Cold Room 22 (Marina)': '22 Nolu Soğuk Oda(Marina)',
    'Center Building Market': 'Merkez Bina Market',
    'Center Building Boutique': 'Merkez Bina Butik',
    'Center Building Hair Salon': 'Merkez Bina Kuaför',
    'Center Building Jewelry Store': 'Merkez Bina Kuyumcu',
    'Central Building Game Hall': 'Merkez Bina Oyun Salonu',
    'Center Building Photographer': 'Merkez Bina Fotoğrafçı',
    'Pine Street Market': 'Pine Sokağı Market',
    'Pine Street Vintage': 'Pine Sokağı Vintage',
    'A Elevator': 'A Asansörü',
    'B Elevator': 'B Asansörü',
    'C Elevator': 'C Asansörü',
    'Lobby Lift': 'Lobby Asansörü',
    'Beach Lift': 'Beach Asansörü',
    'Terrace Lift': 'Teras Asansörü',
    'Main Kitchen Elevator': 'Ana Mutfak Asansörü',
    'Warehouse': 'Ambar',
    'Main Warehouse': 'Ana Depo',
    'Aquapark': 'Aquapark',
    'Anfi Theatre': 'Anfi Tiyatro',
    'Reverse Osmos': 'Arıtma',
    'Beach': 'Beach',
    'Cabana': 'Cabana',
    'Wavegakıran': 'Dalgakıran',
    'Fitness Center': 'Fitness Salonu',
    'Door Security': 'Kapı Güvenliği',
    'Water Sports': 'Su Sporları',
    'Tennis Courts': 'Tenis Kortları',
    'Marangozhane': 'Marangozhane',
    'Marina': 'Marina',
    'Parking': 'Otopark',
    'Efes Kitchen': 'Efes Kitchen',
    'Infirmary': 'Revir',
    'Turkish Bath-Sauna': 'Hamam',
    'Seat Balcony': 'Seyir Balkonu',
    'Tailor Shop': 'Terzihane',
    'Pine Club (4-7)': 'Pine Club (4-7)',
    'Pane Club (8-12)': 'Pane Club (8-12)',
    'Punky Club (13-17)': 'Punky Club (13-17)',
    'Panorama Sunbathing Area': 'Panorama Güneşlenme Alanı',
    'Aqua Hınged Balcony': 'Aqua Ahsap Balkon',
    'Scaffold': 'İskele',
    '1. Lobby': '1. Lobi',
    '2. Lobby': '2. Lobi',
    '3. Lobby': '3. Lobi',
    'Staff Housing Old Block': 'Personel Lojmanı Eski Blok',
    'Staff Housing New Block': 'Personel Lojmanı Yeni Blok',
    'Staff Housing D Block': 'Personel Lojmanı D Blok',
    'Staff Laundry': 'Personel Çamaşırhanesi',
    'Staff Canteen': 'Personel Kantini',
    'Personnel Area Boiler Room': 'Personel Bölgesi Kazan Dairesi',
    'Staff Cafeteria Kitchen': 'Personel Yemekhanesi Mutfağı',
    "3100's Corridor": "3100'ler Koridor",
    "3200's Corridor": "3200'ler Koridor",
    "3300's Corridor": "3300'ler Koridor",
    "3400's Corridor": "3400'ler Koridor",
    "3500's Corridor": "3500'ler Koridor",
    "3600's Corridor": "3600'ler Koridor",
    "3700's Corridor": "3700'ler Koridor",
    "3800's Corridor": "3800'ler Koridor",
    "3900's Corridor": "3900'ler Koridor",
    'Main Kitchen Office': 'Ana Mutfak Ofisi',
    'Key Office': 'Anahtar Ofisi',
    'Animation Office': 'Animasyon Ofisi',
    'Open Office': 'Açık Ofis',
    'Technical Service Office': 'Teknik Servis Ofisi',
    'IT Office': 'Bilgi İşlem',
    'R&D Offıce': 'Arge Ofisi',
    'Cost Control Office': 'Bütçe Kontrol Ofisi',
    'Bungalow 700S Floor Offıce': "Bungalow 700'ler Kat Ofisi",
    '1200S Floor Office': "1200'ler Kat Ofisi",
    '3200S Floor Office': "3200'ler Kat Ofisi",
    '3400S Hk Office': "3400'ler HK Ofisi",
    'Purchasing Offıce': 'Satın Alma Ofisi',
    'Sales And Marketing Office': 'Satış Pazarlama Ofisi',
    'Congress Sales Marketıng Offıce': 'Kongre Satış Pazarlama Ofisi',
    'Offices Tea Room': 'Ofisler Çay Ocağı',
    'General Office': 'Genel Müdürlük',
    'Security Office': 'Güvenlik Ofisi',
    'Guest Relations Office': 'Misafir İlişkileri Ofisi',
    'Housekeepıng Office': 'Housekeeping Ofisi',
    'Human Resources Office': 'İnsan Kaynakları Ofisi',
    'Accounting Office': 'Muhasebe Ofisi',
    'Main Kitchen Dry Food Warehouse': 'Ana Mutfak Kuru Gıda Deposu',
    'Main Kitchen': 'Ana Mutfak',
    'Main Kitchen Dishwashing': 'Ana Mutfak Bulaşıkhane',
    'Main Kitchen Patisserie': 'Ana Mutfak Pastane',
    'Amphitheater Bar Dj Booth': 'Anfi Tiyatro Dj Kabini',
    'Efes Dishwashers': 'Efes Bulaşıkhane',
    'Indoor Pool Engine Room': 'Kapalı Havuz Makine Dairesi',
    'Furnace Boiler Room': 'Kömür Kazan Dairesi',
    'Under Hotel Boiler Room': 'Otel Altı Kazan Dairesi',
    'Air Handling Units': 'Klima Santralleri',
    'Elevator Panel Room': 'Asansör Pano Dairesi',
    'Royal Salon Panel Room': 'Royal Salon Pano Odası',
    'System Room': 'Sistem Odası',
    'Ice Machine Room': 'Buz Makinesi Odası',
    'Generator(Under Terrace Restaurant)': 'Jeneratör(Teras Restoran Altı)',
    'Generator(Under Hotel Boıler)': 'Jeneratör(Otel Altı Kazan Dairesi)',
    'Generator(Staff Area)': 'Jeneratör(Personel Bölgesi)',
    'Laundry Pump Tank': 'Çamaşırhane Pompa Deposu',
    'Iron/Welding Workshop': 'Demir/Kaynak Atölyesi',
    'Broadcast Room': 'Yayın Odası',
    'Teshin Center': 'Teshin Merkezi',
    'Cooling Towers': 'Soğutma Kuleleri',
    'Open Pool Machine Room': 'Açık Havuz Makine Dairesi',
    'Tennis Court Main Distribution Panel': 'Tenis Kortu Ana Dağıtım Panosu',
    'Superior Club': 'Superior Club',
    'Standart Club': 'Standart Club',
    'Club Studio': 'Club Studio',
    'Infinity Villa': 'Infinity Villa',
    'Business Suite': 'Business Suite',
    'Standart Suite with Terrace': 'Standart Suite with Terrace',
    'Junior Room with Sea View': 'Junior Room with Sea View',
    'Superior Suite with Pool': 'Superior Suite with Pool',
    'Large Land View': 'Large Land View',
    'Superior Suite': 'Superior Suite',
    'Std. Suite with Terrace&Garden': 'Std. Suite with Terrace&Garden',
    'Room with Garden View': 'Room with Garden View',
    'Standard Room Pool Garden View': 'Standard Room Pool Garden View',
    'Room with Sea View': 'Room with Sea View',
    'Standart Suite': 'Standart Suite',
    'Superior Room with Sea View': 'Superior Room with Sea View',
    'Villa': 'Villa',
    'Junior Club': 'Junior Club',
    'Pool': 'Havuzlar',
    'Komite 4 Salonu': 'Komite 4 Salonu',
    'Marina Düğün Alanı': 'Marina Düğün Alanı',
    'Panorama Düğün Alanı': 'Panorama Düğün Alanı',
    'Komite 3 Salonu': 'Komite 3 Salonu',
    'Komite 5 Salonu': 'Komite 5 Salonu',
    'Komite 1 Salonu': 'Komite 1 Salonu',
    'Komite 2 Salonu': 'Komite 2 Salonu',
    'Sun Deck': 'Sun Deck',
    'Standart Oda': 'Standart Oda',
    'Sehir ve Deniz Manzarali Oda': 'Sehir ve Deniz Manzarali Oda',
    'Marina Deluxe Oda': 'Marina Deluxe Oda',
    'Standart Oda/ Engelli Odasi': 'Standart Oda/ Engelli Odasi',
    'Suit': 'Suit',
    'Tas Ev': 'Tas Ev',
    'Pools': 'Havuzlar',
    'Meeting Rooms': 'Toplantı Odalar',
    'Food & Beverage': 'Yiyecek & İçecek',
    'General Areas/Restrooms': 'Genel Alanlar ve Dinlenme Odaları',
    'SPA ve WELLNESS': 'SPA ve WELLNES',
    'Committee Meeting Room': 'Komite Toplantı Odası',
    'General Areas / Parking Lot': 'Genel Alanlar / Otopark',
    'General Areas/Lobby': 'Genel Alanlar / Lobi',
    'Public Areas/Stairs': 'Açık Alanlar / Merdivenler',
    'General Areas/Elevator': 'Genel Alanlar / Asansör',
    'General Areas': 'Genel Alanlar',
    'Offices HT': 'Ofisler HT',
    'Warehouses': 'Depolar',
    'Technical HT': 'Teknik HT',
    'Kitchen': 'Mutfak',
    'Meeting Room': 'Toplantı Odaları',
    'Cabanas': 'Cabanalar',
    'Cafe & Restaurants': 'Cafe ve Restoranlar',
    'Bars': 'Barlar',
    'Wc- Shower Dressing Cabins': 'Tuvalet - Duş Kabinleri',
    'Cold Rooms': 'Soğuk Odalar',
    'Shops': 'Mağazalar',
    'Elevators': 'Asansörler',
    'Locations': 'Lokasyonlar',
    'Staff Area': 'Personel Bölgesi',
    'Corridors': 'Koridorlar',
    'Offices PB': 'Ofisler PB',
    'Technical PB': 'Teknik PB',
    "ExportLimitations_Limitless": "Limitsiz",
    "ExportLimitations_Daily": "Günlük",
    "ExportLimitations_Weekly": "Haftalık",
    "ExportLimitations_Monthly": "Aylık",
    "ExportLimitations_Yearly": "Yıllık",
    'kuspbKVKK' : `
    

1. KAPSAM

1.1. İşbu kişisel verilerin korunması politikasının (“Politika”) amacı, Göçtur Turizm Yatırım ve Ticaret A.Ş, Pine Bay Holiday Resort Hotel’in (“Göçtur”); kişisel verileri, 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun (“Kanun”) düzenlemeleri ile uyumlu olarak işlemesini sağlamaktır. Kanunun ihlali Göçtur tarafından ciddi bir şekilde ele alınacak olup, mevzuat hükümleri ve disiplin prosedürleri kapsamında değerlendirilecektir. Kanunun amaçları çerçevesinde, aşağıdaki tanımlar esas alınacaktır:

1.1.1. Kişisel Veri: Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi;

1.1.2. Kişisel Verilerin İşlenmesi: Kişisel Verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlemi

1.1.3. Özel Nitelikli Kişisel Veriler: Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili verileri ile biyometrik ve genetik verilerini;

1.1.4. Veri Sorumlusu: Kişisel Verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan herhangi bir gerçek veya tüzel kişiyi ve Göçtur’u;

1.1.5. Veri İşleyen: Göçtur tarafından verilen yetkiye dayanarak onun adına Kişisel Verileri işleyen üçüncü bir gerçek veya tüzel kişiyi;

1.1.6. Veri Sahibi: Kişisel Verileri işlenen gerçek kişiyi;

1.1.7. Veri Kayıt Sistemi: Göçtur tarafından kullanılan Kişisel Verilerin belirli kriterlere göre yapılandırılarak işlendiği kayıt sistemini;

1.1.8. Kurul: Kişisel Verileri Koruma Kurulunu;

1.1.9. Kurum: Kişisel Verileri Koruma Kurumunu;

1.1.10. Kanun: 7 Nisan 2016 tarihli ve 29677 sayılı Resmi Gazete’de yayınlanmış olan 6698 sayılı Kişisel Verilerin Korunması Kanunu’nu ifade etmektedir.

1.2. İşbu Politika ile Göçtur, Veri Sahibini bilgilendirmeyi amaçlamaktadır ve içeriği aşağıdaki şekildedir:

1.2.1. Göçtur tarafından toplanan Kişisel Verilerin içerik ve kategorileri; kullanılış ve aktarım seçenekleri;

1.2.2. Kişisel Verilerin işleniş biçimleri;

1.2.3. Kişisel Verilerin muhafaza edilme biçimleri;

1.2.4. Kişisel Veri Sahiplerinin hakları;

1.2.5. Kişisel Verilerin korunmasına ilişkin alınan önlemler;

2. Kişisel Verinin İşlenmesine Dair Esaslar

2.1. Göçtur’un amacı, ilgili ticaret sicil müdürlüğü nezdinde tescil ve ilan edilmiş esas sözleşmesinde belirtilmiş olan amaçların bütünüdür.

2.2. Göçtur’un amacı ile ilişkili olarak; Yönetim Kurulu Üyeleri, müşterileri, ziyaretçileri, internet sitesi kullanıcıları, çalışanları, şubeleri, üçüncü kişi acenteler, tedarikçileri ve bunların yetkililerinden toplanabilecek ve işlenebilecek Kişisel Veriler aşağıda sıralanır ve bu liste Göçtur amaçları doğrultusunda genişletilebilir. (Aşağıda belirtilen Kişisel Veriler Veri Sahibi kişi grubuna göre farklılık göstermektedir):

2.2.1. Nüfus cüzdanı, sürücü belgesi, pasaport, nüfus kayıt örneği, askerlik durumuna ve milliyetine ilişkin bilgi, vesikalık fotoğraf, ikametgah belgesi ve/veya bunların fiziki veya dijital suretleri;

2.2.2. Banka hesap bilgisi, fatura bilgisi, kredi kartı bilgisi;

2.2.3. Vergi dairesi bilgisi, vergi numarası, vergi indirim belgesi;

2.2.4. Doğum tarihi ve doğum yeri bilgisi, anne baba adları;

2.2.5. Her türlü adres, telefon, e-posta vb. iletişim bilgisi, kartvizit;

2.2.6. Genel sağlık raporu, kan grubu karneleri veya kan grubunu gösterir belge, varsa sürekli kullandığı ilaçların bilgisi, hepatit-b ve HIV HBS test sonuçları, akciğer film raporu, portör muayene sonuç belgesi, özürlülük, sakatlık, engellilik durumunu gösterir sağlık raporu, engelli kartı ve sair belgeler ve bu belgeler üzerindeki bilgiler;

2.2.7. Çalışanın bakmakla yükümlü olduğu kişilerin adı ve soyadı;

2.2.8. Veri sahibinin acil durum kişisine ilişkin iletişim bilgisi;

2.2.9. Öğrenim durumu ve yabancı dil bilgisi, diploma/ mesleki sertifika suretleri

2.2.10. Sunum, eğitim, toplantı, seminer, animasyon vb. etkinliklerde çekilen ve güvenlik nedeniyle kaydedilen fotoğraf ve video kayıtları;

2.2.11. Ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili, sabıka kaydı dâhil olmak üzere çeşitli bilgiler;

2.2.12. Çalışanların işyeri giriş çıkış kayıtları ve yüz taraması;

2.2.13. Sosyal Güvenlik Kurumu numarası;

2.2.14. Araç plakası;

2.2.15. Veri sahibinin imzasını tasdik eder nitelikte herhangi bir resmi belge;

2.2.16. İnternet sitesi giriş, çıkış ve gezinme bilgisi, şifre ve parola bilgisi, IP adresi;

2.2.17. Oda numarası, birlikte konakladığı kişilerin bilgisi;

2.2.18. Hukuki uyuşmazlık bilgileri

2.3. Göçtur, KVKK md. 5(2)(c)’de düzenlenen istisnalar hariç olmak kaydıyla, Kişisel Verileri sadece aşağıda belirtilen amaç ve dayanaklar çerçevesinde işleyeceğini taahhüt etmektedir;

2.3.1. Konaklama hizmetinin sunulması;

2.3.2. Bülten aboneliği;

2.3.3. Havuz ve aquapark hizmetinin sunulması;

2.3.4. Restoran ve catering hizmetinin sunulması;

2.3.5. Sunulan ürün ve hizmetlerden faydalandırılabilmesi için gerekli iş ve operasyonel süreçlerin yürütülmesi;

2.3.6. Müşteri memnuniyeti, kalite kontrol, geri bildirim süreçlerinin yönetimi, müşteri ilişkilerinin yönetimi;

2.3.7. Ürün ve hizmetlerin özelleştirilerek verilmesi için gerekli çalışmaların ve iş süreçlerinin yürütülmesi;

2.3.8. Sunulan ürün ve hizmetlerin ilgili kişilerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek kişilere önerilmesi ve tanıtılması için gerekli çalışmaların yapılması;

2.3.9. Operasyon ve tanıtım faaliyetleri ve kampanya düzenleme;

2.3.10. Düğün, davet, kongre, seminer, eğitim, toplantı vb. etkinliklerin organizasyonu;

2.3.11. Daha önceden elde edilmiş verilerin yenilenen işlemlerde kullanılması;

2.3.12. Ticari ve/veya hukuki uyuşmazlıkların çözümü;

2.3.13. Veri güvenliğinin temini amacıyla verilerin yurtiçi serverlara aktarılması, verilerin arşivi, yedeklenmesi, istatistiki ve güvenliği;

2.3.14. Dış ve iç denetim, muhasebe, vergi süreçlerinin yönetimi;

2.3.15. İç veri aktarımının sağlanması;

2.3.16. Geleceğe dönük ticari ve organisazyonel planlama yapılması, entegre yönetim sisteminin uygulanması;

2.3.17. Geçmiş çalışmaların takibi;

2.3.18. İş yerinde düzen ve kontrol, yönetim, uyumun sağlanması;

2.3.19. İnsan kaynakları süreçlerinin planlanması ve icrası, çalışanların özlük faaliyetlerinin yürütülmesi; işe alım sürecinin yönetilmesi ve süreç işleyişinin kolaylaştırılması;

2.3.20. WİFİ hizmetinin sunulması,

2.3.21. Pazarlama, yeni ürün ve hizmetlerin geliştirilmesi,

2.3.22. Reklam, kampanya, promosyon süreçlerinin yönetimi ve yürütülmesi, tanıtım faaliyetlerinin yürütülmesi;

2.3.23. İletişim faaliyetlerinin icrası, ulusal ve dini bayram ve özel günlerde tebrik mesaj ve e-postaların gönderilmesi,

2.3.24. Pazarlama analiz çalışmalarının yürütülmesi;

2.3.25. Ürün ve hizmetlerin geliştirilmesi, bu yönde çalışmaların yürütülmesi;

2.3.26. Sanal Pos ile tahsilat yapılması;

2.3.27. Bilgi güvenliği süreçlerinin icrası, planlanması, alt yapı oluşturulması;

2.3.28. Fiziksel mekân güvenliğinin temini;

2.3.29. Ziyaretçi kayıtlarının oluşturulması;

2.3.30. Demirbaşların ve/veya kaynakların güvenliğinin temini;

2.3.31. Kurum operasyonlarının güvenliğinin temini;

3. Veri Toplama Yöntemi Göçtur, Kişisel Verileri aşağıdaki belirtilen yöntemler ile toplayacaktır:

3.1. Elektronik posta;

3.2. Faks;

3.3. Telefon;

3.4. SMS;

3.5. Posta;

3.6. Kurye;

3.7. Göçtur ve şubelerinin internet sitesi ve sosyal medya hesapları, Sanal Ortamlar;

3.8. Turizm acentelerinin aracılığıyla fiziken ve/veya dijital yollarla;

3.9. Elden teslim;

3.10. Sözleşmeler, başvuru formları.

4. İşleme ve Aktarım İzni

4.1. Yurtiçinde İşleme ve Aktarım: Göçtur’un Kişisel Verileri yurtiçinde işlemesi ve üçüncü gerçek ve tüzel kişilere aktarımı ilgili kişinin (Veri Sahibinin) açık rızası ile mümkün olup açık rıza yoksa ancak aşağıdaki şartların varlığında gerçekleştirilecektir:

4.1.1. Kanunlarda açıkça öngörülmesi;

4.1.2. Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün korunması için zorunlu olması;

4.1.3. Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait Kişisel Verilerin işlenmesinin gerekli olması;

4.1.4. Göçtur’un hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması;

4.1.5. İlgili kişinin kendisi tarafından alenileştirilmiş olması;

4.1.6. Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması;

4.1.7. İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, Göçtur ve/ veya diğer Veri Sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması.

4.2. Özel Nitelikli Kişisel Verilerin İşleme ve Aktarımı:

4.2.1. Göçtur özel nitelikli Kişisel Verileri ancak Veri Sahibinin açık rızası ile işleyip yurtiçinde aktarabilir.

4.2.2. Sağlık ve cinsel hayata ilişkin olmayan Kişisel Veriler, kanunlarda öngörülen hâllerde ilgili kişinin açık rızası aranmaksızın işlenebilir.

4.2.3. Sağlık ve cinsel hayata ilişkin Kişisel Veriler ise ancak kamu sağlığının korunması, koruyucu hekimlik, tıbbi teşhis, tedavi ve bakım hizmetlerinin yürütülmesi, sağlık hizmetleri ile finansmanının planlanması ve yönetimi amacıyla, sır saklama yükümlülüğü altında bulunan kişiler veya yetkili kurum ve kuruluşlar tarafından ilgilinin açık rızası aranmaksızın işlenebilir.

4.3. Yurtdışında Kişisel Veri İşleme ve Aktarım:

4.3.1. Göçtur ancak Veri Sahiplerinin açık rızası ile Kişisel Verileri yurtdışında işleyebilir ve aktarabilir.

4.3.2. Göçtur yukarıda 4.1. ve 4.2.’de belirtilen şartların mevcudiyeti ve kişisel verinin aktarılacağı yabancı ülkede aşağıda belirtilen hususların bulunması halinde Veri Sahibinin açık rızası olmaksızın Kişisel Verileri yurt dışına aktarabilir;

4.3.3. Kişisel Verinin aktarılacağı yabancı ülkede yeterli korumanın bulunması;

4.3.4. Yeterli korumanın bulunmaması durumunda Göçtur’un ve ilgili yabancı ülkedeki veri sorumlularının yeterli bir korumayı yazılı olarak taahhüt etmeleri ve Kurulun izninin bulunması halinde Göçtur Kişisel Verileri yurt dışına aktarabilir.

4.3.5. Uluslararası sözleşme hükümleri saklı kalmak üzere, Türkiye’nin veya ilgili Veri Sahibinin menfaatinin ciddi bir şekilde zarar göreceği durumlarda, Göçtur ancak ilgili kamu kurum veya kuruluşunun görüşünü alarak Kurulun izniyle yurt dışına aktarılabilir.

5. Kişisel Verilerin Güvenliği

5.1. Göçtur, Kişisel Verilerin güvenliğini aşağıdaki amaçları gerçekleştirmek için sağlayacak ve işbu amaçları sağlamak için uygun güvenlik düzeyini temin etmeye yönelik gerekli her türlü teknik ve idari tedbirleri alacaktır:

5.1.1. Kişisel Verilerin hukuka aykırı olarak işlenmesini önlemek;

5.1.2. Kişisel Verilere hukuka aykırı olarak erişilmesini önlemek;

5.1.3. Kişisel Verilerin muhafazasını sağlamak

5.2. Göçtur, Kişisel Verilerin kendi adına başka bir gerçek veya tüzel kişi tarafından işlenmesi hâlinde, bu Politikanın

5.1. bölümünde belirtilen tedbirlerin alınması hususunda bu diğer Veri İşleyenlerle birlikte müştereken sorumludur.

5.3. Göçtur, kendi kurum veya kuruluşunda, Kanun hükümlerinin uygulanmasını sağlamak amacıyla gerekli denetimleri yapmak veya yaptırmak zorundadır.

5.4. Göçtur ile Veri İşleyenler, öğrendikleri Kişisel Verileri Kanun hükümlerine aykırı olarak başkasına açıklayamaz ve işleme amacı dışında kullanamazlar. Bu yükümlülük görevlerinin sona ermesinden sonra da devam eder.

5.5. İşlenen Kişisel Verilerin kanuni olmayan yollarla başkaları tarafından elde edilmesi hâlinde, Göçtur bu durumu 72 saat içerisinde Veri Sahibine ve Kurula bildirir. Kurul, gerekmesi hâlinde bu durumu, kendi internet sitesinde ya da uygun göreceği başka bir yöntemle ilan edebilir.

6. Veri Sahibinin Hakları

6.1. Herkes Göçtur’a başvurarak kendisiyle ilgili aşağıdaki haklara sahiptir.

6.1.1. Kişisel Verilerinin işlenip işlenmediğini öğrenme;

6.1.2. Kişisel Verileri işlenmişse buna ilişkin bilgi talep etme;

6.1.3. Kişisel Verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme;

6.1.4. Yurt içinde veya yurt dışında Kişisel Verilerin aktarıldığı üçüncü kişileri bilme;

6.1.5. Kişisel Verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme;

6.1.6. KVKK m. 6 çerçevesinde Kişisel Verilerin silinmesini veya yok edilmesini isteme;

6.1.7. İşbu Politika m. 6.1.5. ve m. 6.1.6 bölümleri uyarınca yapılan işlemlerin, Kişisel Verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme;

6.1.8. İşlenen Kişisel Verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle Veri Sahibinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme ve

6.1.9. Kişisel Verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme.

6.2. 6.1.’de belirtilen hakların kullanılması için Kişiler Verilere ilişkin olarak ilgili kişilerin kimliklerinin tespitini sağlayacak bilgiler ile birlikte söz konusu taleplerin aşağıdaki iletişim yollarından yararlanarak yazılı şekilde iletilmesi gerekmektedir:

6.2.1. kvkk@goctur.com.tr adresine, elektronik posta vasıtasıyla; veya 6.2.2. “Bayraklıdede Mah. Kuştur Cd. No:25 09400 Kuşadası / Aydın / Türkiye” adresine posta vasıtasıyla.

7. Kişisel Verilerin Doğru ve Güncel Olarak Muhafaza Edilmesine İlişkin Önlemler Göçtur, Kişisel Verileri aşağıdaki yöntemler dâhilinde doğru ve güncel olarak muhafaza etmektedir:

7.1. Günlük yedeklemeler;

7.2. Firewall;

7.3. Antivirüs programları;

7.4. Sanal ortam erişiminde şifreleme sistemleri ve yetkilerde kısıtlamalar;

7.5. Oda ve dolaplara kartlı, anahtarlı ve şifreli giriş sistemleri;

7.6. Gizlilik sözleşmeleri ve gizlilik taahhütnameleri

8. Kişisel Verilerin Korunması Politikasında Yapılacak Değişiklikler Göçtur, işbu Politikada faaliyetlerin gerektirdiği ölçüde veya yasal açıdan gerekli olan değişiklikleri yapabilir. Söz konusu değişikliğe uğramış olan Politika metninin http://www.pinebay.com/ sitesinde paylaşılması ile birlikte geçerlilik kazanacaktır.
`,
    'xkukuKVKK' : `
    GÖÇTUR
DOUBLETREE BY HILTON KUŞADASI
KİŞİSEL VERİLERİN KORUNMASI POLİTİKASI
1. Kapsam
1.1. İşbu kişisel verilerin korunması politikasının (“Politika”) amacı, Göçtur Turizm
Yatırım ve Ticaret A.Ş, Doubletree By Hilton Kuşadası Hotel’in (“Göçtur”); kişisel
verileri, 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun (“Kanun”) düzenlemeleri
ile uyumlu olarak işlemesini sağlamaktır. Kanunun ihlali Göçtur tarafından ciddi bir
şekilde ele alınacak olup, mevzuat hükümleri ve disiplin prosedürleri kapsamında
değerlendirilecektir. Kanunun amaçları çerçevesinde, aşağıdaki tanımlar esas
alınacaktır:
1.1.1. Kişisel Veri: Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi;
1.1.2. Kişisel Verilerin İşlenmesi: Kişisel Verilerin tamamen veya kısmen otomatik olan
ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan
yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi,
yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle
getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde
gerçekleştirilen her türlü işlemi;
1.1.3. Özel Nitelikli Kişisel Veriler: Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi
inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da
sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle
ilgili verileri ile biyometrik ve genetik verilerini;
1.1.4. Veri Sorumlusu: Kişisel Verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri
kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan herhangi bir gerçek
veya tüzel kişiyi ve Göçtur’u;
1.1.5. Veri İşleyen: Göçtur tarafından verilen yetkiye dayanarak onun adına Kişisel
Verileri işleyen üçüncü bir gerçek veya tüzel kişiyi;
1.1.6. Veri Sahibi: Kişisel Verileri işlenen gerçek kişiyi;
1.1.7. Veri Kayıt Sistemi: Göçtur tarafından kullanılan Kişisel Verilerin belirli kriterlere
göre yapılandırılarak işlendiği kayıt sistemini;
1.1.8. Kurul: Kişisel Verileri Koruma Kurulunu;
1.1.9. Kurum: Kişisel Verileri Koruma Kurumunu;
1.1.10. Kanun: 7 Nisan 2016 tarihli ve 29677 sayılı Resmi Gazete’de yayınlanmış olan
6698 sayılı Kişisel Verilerin Korunması Kanunu’nu ifade etmektedir.
1.2. İşbu Politika ile Göçtur, Veri Sahibini bilgilendirmeyi amaçlamaktadır ve içeriği
aşağıdaki şekildedir:
1.2.1. Göçtur tarafından toplanan Kişisel Verilerin içerik ve kategorileri; kullanılış ve
aktarım seçenekleri;
1.2.2. Kişisel Verilerin işleniş biçimleri;
1.2.3. Kişisel Verilerin muhafaza edilme biçimleri;
1.2.4. Kişisel Veri Sahiplerinin hakları;
1.2.5. Kişisel Verilerin korunmasına ilişkin alınan önlemler;
2. Kişisel Verinin İşlenmesine Dair Esaslar
2.1. Göçtur’un amacı, ilgili ticaret sicil müdürlüğü nezdinde tescil ve ilan edilmiş esas
sözleşmesinde belirtilmiş olan amaçların bütünüdür.
2.2. Göçtur’un amacı ile ilişkili olarak; Yönetim Kurulu Üyeleri, müşterileri, ziyaretçileri,
internet sitesi kullanıcıları, çalışanları, şubeleri, üçüncü kişi acenteler, tedarikçileri ve
bunların yetkililerinden toplanabilecek ve işlenebilecek Kişisel Veriler aşağıda sıralanır
ve bu liste Göçtur amaçları doğrultusunda genişletilebilir. (Aşağıda belirtilen Kişisel
Veriler Veri Sahibi kişi grubuna göre farklılık göstermektedir):
2.2.1. Nüfus cüzdanı, sürücü belgesi, pasaport, nüfus kayıt örneği, askerlik durumuna ve
milliyetine ilişkin bilgi, vesikalık fotoğraf, ikametgah belgesi ve/veya bunların fiziki
veya dijital suretleri;
2.2.2. Banka hesap bilgisi, fatura bilgisi, kredi kartı bilgisi;
2.2.3. Vergi dairesi bilgisi, vergi numarası, vergi indirim belgesi;
2.2.4. Doğum tarihi ve doğum yeri bilgisi, anne baba adları;
2.2.5. Her türlü adres, telefon, e-posta vb. iletişim bilgisi, kartvizit;
2.2.6. Genel sağlık raporu, kan grubu karneleri veya kan grubunu gösterir belge, varsa
sürekli kullandığı ilaçların bilgisi, hepatit-b ve HIV HBS test sonuçları, akciğer film
raporu, portör muayene sonuç belgesi alerji ve beslenme alışkanlıklarının bilgisi,
özürlülük, sakatlık, engellilik durumunu gösterir sağlık raporu, engelli kartı ve sair
belgeler ve bu belgeler üzerindeki bilgiler;
2.2.7. Çalışanın bakmakla yükümlü olduğu kişilerin adı ve soyadı;
2.2.8. Veri sahibinin acil durum kişisine ilişkin iletişim bilgisi;
2.2.9. Öğrenim durumu ve yabancı dil bilgisi, diploma/ mesleki sertifika suretleri
2.2.10. Sunum, eğitim, toplantı, seminer, animasyon vb. etkinliklerde çekilen ve güvenlik
nedeniyle kaydedilen fotoğraf ve video kayıtları;
2.2.11. Ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili, sabıka kaydı dâhil olmak üzere
çeşitli bilgiler;
2.2.12. Çalışanların işyeri giriş çıkış kayıtları ve yüz taraması;
2.2.13. Sosyal Güvenlik Kurumu numarası;
2.2.14. Araç plakası;
2.2.15. Veri sahibinin imzasını tasdik eder nitelikte herhangi bir resmi belge;
2.2.16. İnternet sitesi giriş, çıkış ve gezinme bilgisi, şifre ve parola bilgisi, IP adresi;
2.2.17. Oda numarası, birlikte konakladığı kişilerin adı, soyadı, iletişim bilgileri, TC
kimlik numarası bilgisi;
2.2.18. Hukuki uyuşmazlık bilgileri.
2.3. Göçtur, KVKK md. 5(2)(c)’de düzenlenen istisnalar hariç olmak kaydıyla, Kişisel
Verileri sadece aşağıda belirtilen amaç ve dayanaklar çerçevesinde işleyeceğini taahhüt
etmektedir;
2.3.1. Konaklama hizmetinin sunulması;
2.3.2. Bülten aboneliği;
2.3.3. Havuz ve aquapark hizmetinin sunulması;
2.3.4. Restoran ve catering hizmetinin sunulması;
2.3.5. Sunulan ürün ve hizmetlerden faydalandırılabilmesi için gerekli iş ve operasyonel
süreçlerin yürütülmesi;
2.3.6. Müşteri memnuniyeti, kalite kontrol, geri bildirim süreçlerinin yönetimi, müşteri
ilişkilerinin yönetimi;
2.3.7. Ürün ve hizmetlerin özelleştirilerek verilmesi için gerekli çalışmaların ve iş
süreçlerinin yürütülmesi;
2.3.8. Sunulan ürün ve hizmetlerin ilgili kişilerin beğeni, kullanım alışkanlıkları ve
ihtiyaçlarına göre özelleştirilerek kişilere önerilmesi ve tanıtılması için gerekli
çalışmaların yapılması;
2.3.9. Operasyon ve tanıtım faaliyetleri ve kampanya düzenleme;
2.3.10. Düğün, davet, kongre, seminer, eğitim, toplantı vb. etkinliklerin organizasyonu;
2.3.11. Daha önceden elde edilmiş verilerin yenilenen işlemlerde kullanılması;
2.3.12. Ticari ve/veya hukuki uyuşmazlıkların çözümü;
2.3.13. Veri güvenliğinin temini amacıyla verilerin yurtiçi serverlara aktarılması, verilerin
arşivi, yedeklenmesi, istatistiki ve güvenliği;
2.3.14. Dış ve iç denetim, muhasebe, vergi süreçlerinin yönetimi;
2.3.15. İç veri aktarımının sağlanması;
2.3.16. Geleceğe dönük ticari ve organisazyonel planlama yapılması, entegre yönetim
sisteminin uygulanması;
2.3.17. Geçmiş çalışmaların takibi;
2.3.18. İş yerinde düzen ve kontrol, yönetim, uyumun sağlanması;
2.3.19. İnsan kaynakları süreçlerinin planlanması ve icrası, çalışanların özlük
faaliyetlerinin yürütülmesi; işe alım sürecinin yönetilmesi ve süreç işleyişinin
kolaylaştırılması;
2.3.20. WİFİ hizmetinin sunulması,
2.3.21. Pazarlama, yeni ürün ve hizmetlerin geliştirilmesi,
2.3.22. Reklam, kampanya, promosyon süreçlerinin yönetimi ve yürütülmesi, tanıtım
faaliyetlerinin yürütülmesi;
2.3.23. İletişim faaliyetlerinin icrası, ulusal ve dini bayram ve özel günlerde tebrik mesaj
ve e-postaların gönderilmesi,
2.3.24. Pazarlama analiz çalışmalarının yürütülmesi;
2.3.25. Ürün ve hizmetlerin geliştirilmesi, bu yönde çalışmaların yürütülmesi;
2.3.26. Sanal Pos ile tahsilat yapılması;
2.3.27. Bilgi güvenliği süreçlerinin icrası, planlanması, alt yapı oluşturulması;
2.3.28. Fiziksel mekân güvenliğinin temini;
2.3.29. Ziyaretçi kayıtlarının oluşturulması;
2.3.30. Demirbaşların ve/veya kaynakların güvenliğinin temini;
2.3.31. Kurum operasyonlarının güvenliğinin temini;
3. Veri Toplama Yöntemi
Göçtur, Kişisel Verileri aşağıdaki belirtilen yöntemler ile toplayacaktır:
3.1.1. Elektronik posta;
3.1.2. Faks;
3.1.3. Telefon;
3.1.4. SMS;
3.1.5. Posta;
3.1.6. Kurye;
3.1.7. Göçtur ve şubelerinin internet sitesi ve sosyal medya hesapları, Sanal Ortamlar;
3.1.8. Turizm acentelerinin aracılığıyla fiziken ve/veya dijital yollarla;
3.1.9. Elden teslim;
3.1.10. Sözleşmeler, başvuru formları.
4. İşleme ve Aktarım İzni
4.1. Yurtiçinde İşleme ve Aktarım:
Göçtur’un Kişisel Verileri yurtiçinde işlemesi ve üçüncü gerçek ve tüzel kişilere aktarımı
ilgili kişinin (Veri Sahibinin) açık rızası ile mümkün olup açık rıza yoksa ancak aşağıdaki
şartların varlığında gerçekleştirilecektir:
4.1.1. Kanunlarda açıkça öngörülmesi;
4.1.2. Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya rızasına
hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya
beden bütünlüğünün korunması için zorunlu olması;
4.1.3. Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla,
sözleşmenin taraflarına ait Kişisel Verilerin işlenmesinin gerekli olması;
4.1.4. Göçtur’un hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması;
4.1.5. İlgili kişinin kendisi tarafından alenileştirilmiş olması;
4.1.6. Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması;
4.1.7. İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, Göçtur ve/ veya
diğer Veri Sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması.
4.2. Özel Nitelikli Kişisel Verilerin İşleme ve Aktarımı:
4.2.1. Göçtur özel nitelikli Kişisel Verileri ancak Veri Sahibinin açık rızası ile işleyip
yurtiçinde aktarabilir.
4.2.2. Sağlık ve cinsel hayata ilişkin olmayan Kişisel Veriler, kanunlarda öngörülen
hâllerde ilgili kişinin açık rızası aranmaksızın işlenebilir.
4.2.3. Sağlık ve cinsel hayata ilişkin Kişisel Veriler ise ancak kamu sağlığının korunması,
koruyucu hekimlik, tıbbi teşhis, tedavi ve bakım hizmetlerinin yürütülmesi, sağlık
hizmetleri ile finansmanının planlanması ve yönetimi amacıyla, sır saklama
yükümlülüğü altında bulunan kişiler veya yetkili kurum ve kuruluşlar tarafından
ilgilinin açık rızası aranmaksızın işlenebilir.
4.3. Yurtdışında Kişisel Veri İşleme ve Aktarım:
4.3.1. Göçtur ancak Veri Sahiplerinin açık rızası ile Kişisel Verileri yurtdışında
işleyebilir ve aktarabilir.
4.3.2. Göçtur yukarıda 4.1. ve 4.2.’de belirtilen şartların mevcudiyeti ve kişisel verinin
aktarılacağı yabancı ülkede aşağıda belirtilen hususların bulunması halinde Veri
Sahibinin açık rızası olmaksızın Kişisel Verileri yurt dışına aktarabilir;
a. Kişisel Verinin aktarılacağı yabancı ülkede yeterli korumanın bulunması;
b. Yeterli korumanın bulunmaması durumunda Göçtur’un ve ilgili yabancı ülkedeki
veri sorumlularının yeterli bir korumayı yazılı olarak taahhüt etmeleri ve Kurulun
izninin bulunması halinde Göçtur Kişisel Verileri yurt dışına aktarabilir.
4.3.3. Uluslararası sözleşme hükümleri saklı kalmak üzere, Türkiye’nin veya ilgili Veri
Sahibinin menfaatinin ciddi bir şekilde zarar göreceği durumlarda, Göçtur ancak
ilgili kamu kurum veya kuruluşunun görüşünü alarak Kurulun izniyle yurt dışına
aktarılabilir.
5. Kişisel Verilerin Güvenliği
5.1. Göçtur, Kişisel Verilerin güvenliğini aşağıdaki amaçları gerçekleştirmek için
sağlayacak ve işbu amaçları sağlamak için uygun güvenlik düzeyini temin etmeye
yönelik gerekli her türlü teknik ve idari tedbirleri alacaktır:
5.1.1. Kişisel Verilerin hukuka aykırı olarak işlenmesini önlemek;
5.1.2. Kişisel Verilere hukuka aykırı olarak erişilmesini önlemek;
5.1.3. Kişisel Verilerin muhafazasını sağlamak.
5.2. Göçtur, Kişisel Verilerin kendi adına başka bir gerçek veya tüzel kişi tarafından
işlenmesi hâlinde, bu Politikanın 5.1. bölümünde belirtilen tedbirlerin alınması
hususunda bu diğer Veri İşleyenlerle birlikte müştereken sorumludur.
5.3. Göçtur, kendi kurum veya kuruluşunda, Kanun hükümlerinin uygulanmasını sağlamak
amacıyla gerekli denetimleri yapmak veya yaptırmak zorundadır.
5.4. Göçtur ile Veri İşleyenler, öğrendikleri Kişisel Verileri Kanun hükümlerine aykırı
olarak başkasına açıklayamaz ve işleme amacı dışında kullanamazlar. Bu yükümlülük
görevlerinin sona ermesinden sonra da devam eder.
5.5. İşlenen Kişisel Verilerin kanuni olmayan yollarla başkaları tarafından elde edilmesi
hâlinde, Göçtur bu durumu 72 saat içerisinde Veri Sahibine ve Kurula bildirir. Kurul,
gerekmesi hâlinde bu durumu, kendi internet sitesinde ya da uygun göreceği başka bir
yöntemle ilan edebilir.
6. Veri Sahibinin Hakları
6.1. Herkes Göçtur’a başvurarak kendisiyle ilgili aşağıdaki haklara sahiptir.
6.1.1. Kişisel Verilerinin işlenip işlenmediğini öğrenme;
6.1.2. Kişisel Verileri işlenmişse buna ilişkin bilgi talep etme;
6.1.3. Kişisel Verilerin işlenme amacını ve bunların amacına uygun kullanılıp
kullanılmadığını öğrenme;
6.1.4. Yurt içinde veya yurt dışında Kişisel Verilerin aktarıldığı üçüncü kişileri bilme;
6.1.5. Kişisel Verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini
isteme;
6.1.6. Kanunun 7. maddesi çerçevesinde Kişisel Verilerin silinmesini veya yok edilmesini
isteme;
6.1.7. 6.1.5. ve 6.1.6 bölümleri uyarınca yapılan işlemlerin, Kişisel Verilerin aktarıldığı
üçüncü kişilere bildirilmesini isteme;
6.1.8. İşlenen Kişisel Verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi
suretiyle Veri Sahibinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme ve
6.1.9. Kişisel Verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde
zararın giderilmesini talep etme.
6.2. 6.1.’de belirtilen hakların kullanılması için Kişiler Verilere ilişkin olarak ilgili kişilerin
kimliklerinin tespitini sağlayacak bilgiler ile birlikte söz konusu taleplerin aşağıdaki
iletişim yollarından yararlanarak yazılı şekilde iletilmesi gerekmektedir:
6.2.1. kvkk@goctur.com.tr adresine, elektronik posta vasıtasıyla; veya
6.2.2. “Bayraklıdede Mah. Kuştur Cd. No:25 09400 Kuşadası / Aydın / Türkiye”
adresine posta vasıtasıyla.
7. Kişisel Verilerin Doğru ve Güncel Olarak Muhafaza Edilmesine İlişkin Önlemler
Göçtur, Kişisel Verileri aşağıdaki yöntemler dâhilinde doğru ve güncel olarak muhafaza
etmektedir:
7.1. Günlük yedeklemeler;
7.2. Firewall;
7.3. Antivirüs programları;
7.4. Sanal ortam erişiminde şifreleme sistemleri ve yetkilerde kısıtlamalar;
7.5. Oda ve dolaplara kartlı, anahtarlı ve şifreli giriş sistemleri;
7.6. Gizlilik sözleşmeleri ve gizlilik taahhütnameleri.
8. Kişisel Verilerin Korunması Politikasında Yapılacak Değişiklikler
Göçtur, işbu Politikada faaliyetlerin gerektirdiği ölçüde veya yasal açıdan gerekli olan
değişiklikleri yapabilir. Söz konusu değişikliğe uğramış olan Politika metninin
http://www.dtbyhiltonkusadasi.com sitesinde paylaşılması ile birlikte geçerlilik kazanacaktır.
    `,
    'kvkktitle' : 'Kişisel Verilerin Korunması Politikası',
    'cancel' : 'İptal',
    'accept' : 'Kabul Ediyorum',
    'changelanguage' : 'Dil Değiştir',
    'thanksTitle' : 'Teşekkürler!',
    'thanksSubTitle' : 'Geri bildiriminiz için teşekkür ederiz.',
    'submitAgain': 'Anketi Tekrar Gönder',

};

export default trJSON;