import React from 'react';

const TestBanner = () => {
  return (
    <div
      style={{
        backgroundColor: 'red',
        color: 'white',
        textAlign: 'center',
        padding: '10px',
        fontWeight: 'bold',
        position: 'fixed',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '50%',
        zIndex: 1000,
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px'
      }}
    >
      !!! TEST SUNUCUSUNDASINIZ !!!
    </div>
  );
};

export default TestBanner;
