import ApiConstants from 'src/constants/apiConstants';
import BaseHttpService from '../http/baseHttpService';
import { ProcessMenuItem } from 'src/models/processMenuItem';
import { FormConfiguration } from 'src/models/formConfiguration';
import { FormData as LocalFormData } from 'src/models/formData';
import { AxiosResponse } from 'axios';
import {
  ApiResult,
  Error,
  ApiResultPaginated,
  GeneralApiResult,
  PaginatedResult
} from 'src/models/apiResult';
import { ProcessData } from 'src/models/processData';
import FileHelper from 'src/helpers/FileHelper';
import { ProcessAssignment } from 'src/models/processAssignment';
import { RecordStatuses } from 'src/models/recordStatuses';
import { workflowData } from 'src/models/workflowData';
import { workflowHistoryData } from 'src/models/workflowHistoryData';
import { EmbedViewConfiguration } from 'src/models/embedViewConfiguration';
import { ApprovalData } from 'src/models/approvalData';
import { ApproveStatuses } from 'src/models/approveStatus';
import { staffData } from 'src/models/staffData';
import { AssignmentData } from 'src/models/assignmentData';
import { CompassApprovals } from 'src/models/compassApproval';
import { ViewRecordSpec } from 'src/models/viewRecordSpec';
import snackbar from 'src/helpers/SnackbarHelper';

class ProcessesHttpService {
  private _httpService: BaseHttpService;

  constructor() {
    this._httpService = new BaseHttpService(ApiConstants.ProcessesApi);
  }

  async getProcessesAsMenuItem(): Promise<GeneralApiResult<ProcessMenuItem[]>> {
    return this.requestWrapper(
      this._httpService.get<GeneralApiResult<ProcessMenuItem[]>>(
        'api/views/as-menu-item'
      )
    );
  }

  async getProcessConfiguration(
    processCode: string
  ): Promise<GeneralApiResult<FormConfiguration>> {
    return this.requestWrapper(
      this._httpService.get<GeneralApiResult<FormConfiguration>>(
        `api/views/${processCode}/configuration`
      )
    );
  }

  async getProcessEmbedConfiguration(
    processCode: string
  ): Promise<GeneralApiResult<EmbedViewConfiguration>> {
    return this.requestWrapper(
      this._httpService.get<GeneralApiResult<EmbedViewConfiguration>>(
        `/api/views/${processCode}/embed-configuration`
      )
    );
  }

  async getRecordsAsPaginated(
    processCode: string,
    viewRecordSpec: ViewRecordSpec
  ): Promise<GeneralApiResult<PaginatedResult<LocalFormData[]>>> {
    return this.requestWrapper(
      this._httpService.put<GeneralApiResult<PaginatedResult<LocalFormData[]>>>(
        `/${processCode}/`,
        viewRecordSpec
      )
    );
  }

  async getRecord(
    processCode: string,
    recordId: number | string
  ): Promise<GeneralApiResult<ProcessData>> {
    return this.requestWrapper(
      this._httpService.get<GeneralApiResult<ProcessData>>(
        `/${processCode}/${recordId}`
      )
    );
  }

  async getDetailRecords(
    processCode: string,
    startDate: Date,
    endDate: Date,
    masterRecordId: number | string
  ): Promise<GeneralApiResult<LocalFormData[]>> {
    return this.requestWrapper(
      this._httpService.get<GeneralApiResult<LocalFormData[]>>(
        `/${processCode}/detail/${masterRecordId}/?startDate=${startDate?.toISOString()}&endDate=${endDate?.toISOString()}`
      )
    );
  }

  async getWorkflowHistories(
    startDate: Date,
    endDate: Date
  ): Promise<GeneralApiResult<workflowData[]>> {
    return this.requestWrapper(
      this._httpService.get<GeneralApiResult<workflowData[]>>(
        `api/workflows/?startDate=${startDate?.toISOString()}&endDate=${endDate?.toISOString()}`
      )
    );
  }

  async getRecordWorkflowHistory(
    viewCode: string,
    recordId: number
  ): Promise<GeneralApiResult<workflowHistoryData[]>> {
    return this.requestWrapper(
      this._httpService.get<GeneralApiResult<workflowHistoryData[]>>(
        `api/workflows/${viewCode}/${recordId}`
      )
    );
  }

  async getActiveApprovals(): Promise<GeneralApiResult<ApprovalData[]>> {
    return this.requestWrapper(
      this._httpService.get<GeneralApiResult<ApprovalData[]>>(
        'api/workflows/approvals'
      )
    );
  }

  async getActiveAssignments(): Promise<GeneralApiResult<AssignmentData[]>> {
    return this.requestWrapper(
      this._httpService.get<GeneralApiResult<AssignmentData[]>>(
        '/api/workflows/assignments'
      )
    );
  }

  async getRecordHistory(
    processCode: string,
    recordId: number
  ): Promise<ApiResult<LocalFormData[]>> {
    return null;
  }

  async getRecordAsPdf(processCode: string, recordId: number): Promise<any> {
    return this.requestWrapper(
      this._httpService.get(`/${processCode}/${recordId}/export/pdf`, {
        responseType: 'blob'
      })
    );
  }

  async getCompassUserApprovalCount(): Promise<
    GeneralApiResult<CompassApprovals>
  > {
    return this.requestWrapper(
      this._httpService.get<GeneralApiResult<CompassApprovals>>(
        'api/external/user_compass_approvals'
      )
    );
  }

  async getRecordsAsPdf(
    processCode: string,
    rowIds: (number | string)[]
  ): Promise<any> {
    return this.requestWrapper(
      this._httpService.post(`/${processCode}/export-multiple/pdf`, rowIds, {
        responseType: 'blob'
      })
    );
  }

  async getStaffByFilter(
    staff_name_filter: string,
    staff_email_filter: string,
    staff_role_filter: string,
    staff_grade_filter
  ): Promise<GeneralApiResult<staffData[]>> {
    return this.requestWrapper(
      this._httpService.get<GeneralApiResult<staffData[]>>(
        `api/workflows/approvals/staff/?name=${staff_name_filter}&email=${staff_email_filter}&role=${staff_role_filter}&grade=${staff_grade_filter}`
      )
    );
  }

  async reassignApproverStaff(
    workflowId: string | number,
    staffId: string | number
  ): Promise<GeneralApiResult<any>> {
    const data = {
      workflowId: workflowId,
      staffId: staffId
    };

    return this.requestWrapper(
      this._httpService.put('/api/workflows/approvals/staff', data)
    );
  }

  async updateRecord(
    processCode: string,
    recordId: number | string,
    data: LocalFormData
  ): Promise<GeneralApiResult<LocalFormData>> {
    return this.requestWrapper(
      this._httpService.put(`/${processCode}/${recordId}`, data)
    );
  }

  async updateRecordStatus(
    processCode: string,
    recordId: number | string,
    recordStatus: number | string,
    comment: string,
    email: string
  ): Promise<GeneralApiResult<any>> {
    const statusData = {
      recordStatus: recordStatus,
      comment: comment,
      userEmail: email
    };

    return this.requestWrapper(
      this._httpService.put(`/${processCode}/${recordId}/status`, statusData)
    );
  }

  async setApprovalResult(
    workflowId: string | number,
    approvalResult: ApproveStatuses,
    comment: string,
    processName: string,
    recordId: string | number
  ): Promise<GeneralApiResult<any>> {
    const data = {
      workflowId: workflowId,
      approvalResult: approvalResult,
      comment: comment,
      processName: processName
    };

    return this.requestWrapper(
      this._httpService.put(`api/workflows/approvals/${recordId}`, data)
    );
  }

  async setAssignmentResult(
    workflowId: string | number,
    processName: string,
    recordId: string | number
  ): Promise<GeneralApiResult<any>> {
    const data = {
      workflowId: workflowId,
      processName: processName
    };

    return this.requestWrapper(
      this._httpService.put(`api/workflows/assignments/${recordId}`, data)
    );
  }

  async createRecord(
    processCode: string,
    data: LocalFormData,
    files?: File[],
    assignmentId?: number
  ): Promise<GeneralApiResult<LocalFormData>> {
    let base64Files: any[] = [];

    if (files) {
      base64Files = await Promise.all(
        files.map(async (file) => {
          return {
            fileName: file.name,
            Base64String: await FileHelper.convertToBase64String(file)
          };
        })
      );
    }

    const formData = {
      values: data,
      files: base64Files,
      assignmentId: assignmentId
    };

    return this.requestWrapper(
      this._httpService.post(`/${processCode}`, formData)
    );
  }

  async createDetailRecord(
    processCode: string,
    data: LocalFormData,
    masterRecordId: string | number,
    files?: File[],
    assignmentId?: number
  ): Promise<GeneralApiResult<LocalFormData>> {
    let base64Files: any[] = [];

    if (files) {
      base64Files = await Promise.all(
        files.map(async (file) => {
          return {
            fileName: file.name,
            Base64String: await FileHelper.convertToBase64String(file)
          };
        })
      );
    }

    const formData = {
      values: data,
      files: base64Files,
      assignmentId: assignmentId
    };

    return this.requestWrapper(
      this._httpService.post(
        `/${processCode}/detail/${masterRecordId}`,
        formData
      )
    );
  }

  async deleteRecord(
    processCode: string,
    recordId: number | string
  ): Promise<GeneralApiResult<LocalFormData>> {
    return this.requestWrapper(
      this._httpService.delete(`/${processCode}/${recordId}`)
    );
  }

  async deleteRecordFile(
    processCode: string,
    recordId: number | string,
    fileId: number
  ): Promise<GeneralApiResult<LocalFormData>> {
    return this.requestWrapper(
      this._httpService.delete(`/${processCode}/${recordId}/files/${fileId}`)
    );
  }

  async createRecordFiles(
    processCode: string,
    recordId: number | string,
    formFiles: File[]
  ): Promise<GeneralApiResult<LocalFormData>> {
    const data = {
      viewCode: processCode,
      recordId: recordId,
      files: await Promise.all(
        formFiles.map(async (file) => {
          return {
            fileName: file.name,
            base64String: await FileHelper.convertToBase64String(file)
          };
        })
      )
    };
    return this.requestWrapper(
      this._httpService.post(`/${processCode}/${recordId}/files`, data)
    );
  }

  async importRecordsAsExcel(processCode: string, file: File): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);

    return this.requestWrapper(
      this._httpService.post(`/${processCode}/bulk`, formData, {
        responseType: 'blob',
        headers: { 'Content-Type': 'multipart/form-data' }
      })
    );
  }

  async getImportRecordSchemeAsExcel(processCode: string): Promise<any> {
    return this.requestWrapper(
      this._httpService.get(`/${processCode}/bulk`, {
        responseType: 'blob'
      })
    );
  }

  async getRecordAsExcel(
    processCode: string,
    applyGroupping: boolean,
    viewRecordSpec: ViewRecordSpec
  ): Promise<any> {
    return this.requestWrapper(
      this._httpService.put(
        `/${processCode}/export/excel/${applyGroupping}`,
        viewRecordSpec,
        {
          responseType: 'blob'
        }
      )
    );
  }

  // async getUserAssignments(userId: number): Promise<ApiResult<ProcessAssignment[]>> {
  //     return this.requestWrapper(
  //         this._httpService.get<ProcessAssignment[]>(`/api/assignments/${userId}`)
  //     );
  // }

  async downloadFileFromAzure(
    processCode: string,
    fileName: string,
    folderName: string,
    recordId: number | string
  ): Promise<any> {
    return this.requestWrapper(
      this._httpService.get(
        `/${processCode}/${folderName}/${fileName}/${recordId}`,
        { responseType: 'blob' }
      )
    );
  }

  private async requestWrapper<T>(
    request: Promise<AxiosResponse<GeneralApiResult<T>>>
  ): Promise<GeneralApiResult<T>> {
    try {
      const response = await request;
      return response.data;
    } catch (error) {
      return this.formatErrorResponse(error);
    }
  }

  private async formatErrorResponse(
    error: any
  ): Promise<GeneralApiResult<any>> {
    if (error.response) {
      let err: Error = { code: 'Unknown', message: 'Bilinmeyen hata' };
      try {
        if (
          error.response.data instanceof Blob &&
          error.response.data.type === 'application/json'
        ) {
          const responseData = await error.response.data?.text();
          err = JSON.parse(responseData);
        } else {
          err = error.response.data;
        }
      } catch (errorCatch) {
        console.error(errorCatch);

        err = { code: 'Unknown', message: 'Bilinmeyen hata' };
      }

      return error.response.data;
    } else {
      return {
        result: null,
        statusCode: 0,
        message: 'Bilinmeyen hata',
        isSuccess: false,
        error: { code: 'Unknown', message: 'Bilinmeyen hata' }
      };
    }
  }
}

export default new ProcessesHttpService();
