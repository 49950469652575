const ApiConstants = {
  FormApi: process.env.REACT_APP_FORM_API,
  ProcessesApi: process.env.REACT_APP_PROCESSES_API,
  IdentityUrl: process.env.REACT_APP_IDENTITY_URL,
  AdminApi: process.env.REACT_APP_ADMIN_API,
  ReportApi: process.env.REACT_APP_REPORT_API,
  SurveyApi: process.env.REACT_APP_SURVEY_API,
  DevEnv: process.env.REACT_APP_DEV_ENV
};

export default ApiConstants;
