import { Box, Typography } from '@mui/material';
import { SyncLoader } from 'react-spinners';

type LoaderProps = {
  loading: boolean;
  label?: string;
};

function Loader({ loading = true, label = null }: LoaderProps) {
  if (!loading) {
    return null;
  }
  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 9999, // Ensure the loader appears on top of other elements
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {/* Overlay to prevent user interaction */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black color
          zIndex: -1 // Place the overlay behind the loader
        }}
      />

      <SyncLoader color="#005BA8" size={25} />

      <Typography
        sx={{ marginTop: 2, color: 'white', fontSize: '1.2rem', zIndex: 1 }}
      >
        {label || 'Yükleniyor...'}
      </Typography>
    </Box>
  );
}

export default Loader;
