import { FC, ReactNode } from 'react';
import { Box, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from 'src/components/Footer';
import useSidebar from 'src/hooks/useSidebar';
import useAuth from '../../hooks/useAuth';

interface AccentSidebarLayoutProps {
  children?: ReactNode;
}

const AccentSidebarLayout: FC<AccentSidebarLayoutProps> = () => {
  const theme = useTheme();
  const sidebarOpen = useSidebar((selector) => selector.sidebarToggle);
  const shouldChangePassword = useAuth(
    (selector) => selector.user.shouldChangePassword
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
      }}
    >
      {!shouldChangePassword && (
        <>
          <Header />
          <Sidebar />
        </>
      )}
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          pt: `${theme.header.height}`,
          pl:
            sidebarOpen && !shouldChangePassword
              ? `${theme.sidebar.width}`
              : undefined,
          transition: '0.5s cubic-bezier(.36,-0.01,0,.77)'
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Outlet />
        </Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default AccentSidebarLayout;
