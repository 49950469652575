import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios';
import { Button } from '@mui/material';
import AuthService from '../auth/authService';
import i18n from 'src/i18n/i18n';
import { GeneralApiResult } from 'src/models/apiResult';
import { enqueueSnackbar } from 'notistack';
import snackbar from 'src/helpers/SnackbarHelper';

class BaseHttpService {
  private axiosInstance: AxiosInstance;

  constructor(base_url: string) {
    this.axiosInstance = axios.create({
      baseURL: base_url
    });

    this.axiosInstance.interceptors.request.use(async (config) => {
      const token = await AuthService.getAccessToken();

      const language = i18n.language;
      if (token !== null) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      config.headers['Accept-Language'] = language || 'tr';

      return config;
    });

    this.axiosInstance.interceptors.response.use(
      this.handleResponse,
      this.handleError
    );
  }

  private handleResponse(response: AxiosResponse<GeneralApiResult>) {
    if (response?.data?.isSuccess && response?.data?.message) {
      snackbar.success(response.data.message);
    }
    return response;
  }

  private async handleError(error: AxiosError<GeneralApiResult>) {
    if (error?.response?.data?.message) {
      snackbar.error(error.response.data.message);
    }

    if (error?.response?.data == undefined) {
      snackbar.errorAction(
        'Bilinmeyen bir hata ile karşılaşıldı. Lütfen IT Destek üzerinden kayıt açınız.',
        'IT Destek ⇗',
        () => {
          window.open(
            'https://sdpondemand.manageengine.com/app/itdesk/ui/requests',
            '_blank'
          );
        }
      );
    }

    if (error.response && error.response.data instanceof Blob) {
      const text = await error.response.data.text();
      const json = JSON.parse(text);
      snackbar.error(`${json.message}`);
    }

    if (error.response?.status === 401) {
      AuthService.logout();
    }

    return Promise.reject(error);
  }

  async get<T = any, R = AxiosResponse<T>>(
    url: string,
    config: AxiosRequestConfig = null
  ): Promise<R> {
    return this.axiosInstance.get<T, R>(url, config);
  }

  async post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config: AxiosRequestConfig = null
  ): Promise<R> {
    return this.axiosInstance.post<T, R>(url, data, config);
  }

  async put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config: AxiosRequestConfig = null
  ): Promise<R> {
    return this.axiosInstance.put<T, R>(url, data, config);
  }

  async patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config: AxiosRequestConfig = null
  ): Promise<R> {
    return this.axiosInstance.patch<T, R>(url, data, config);
  }

  async delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config: AxiosRequestConfig = null
  ): Promise<R> {
    return this.axiosInstance.delete<T, R>(url, config);
  }
}

export default BaseHttpService;
