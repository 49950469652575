import { Box } from '@mui/material';
import { createContext, useContext, useState, ReactNode } from 'react';
import { ClipLoader, SyncLoader } from 'react-spinners';

interface LoaderContextType {
  showLoader: (opacity?: number) => void;
  hideLoader: () => void;
}

const LoaderContext = createContext<LoaderContextType>({
  showLoader: () => {},
  hideLoader: () => {}
});

export const LoaderProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(false);

  const showLoader = () => setLoading(true);

  const hideLoader = () => setLoading(false);

  return (
    <LoaderContext.Provider value={{ showLoader, hideLoader }}>
      {children}
      {loading && (
        <Box
          sx={{
            position: 'fixed',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: 9999 // Ensure the loader appears on top of other elements
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {/* Overlay to prevent user interaction */}
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black color
              pointerEvents: 'none', // Allow pointer events to pass through
              zIndex: -1 // Place the overlay behind the loader
            }}
          />

          {/* Loader element */}
          <SyncLoader color="#005BA8" size={25} />
        </Box>
      )}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => useContext(LoaderContext);
