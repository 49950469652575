import { useLocation, useRoutes } from 'react-router-dom';
import router from 'src/router';
import { SnackbarProvider, useSnackbar } from 'notistack';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline, IconButton } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { useEffect, useState } from 'react';
import cacheService from './services/storage/cacheService';
import i18n from './i18n/i18n';
import { AuthProvider } from './contexts/OidcAuthContext';
import { LoaderProvider } from './contexts/LoaderContext';
import TestBanner from './components/TestBanner';
import { SnackbarCloseButton } from './helpers/SnackbarHelper';
import ApiConstants from './constants/apiConstants';

function App() {
  const content = useRoutes(router);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const location = useLocation();
  const isTestEnv = ApiConstants.DevEnv
    ? ['development', 'staging', 'test'].includes(
        ApiConstants.DevEnv.trim().toLowerCase()
      )
    : true;

  useEffect(() => {
    // get recent pages from local storage
    let recentPages = cacheService.get<string[]>('recentPages') || [];
    // if the current page is not in recent pages, add it. If it exists remove it and add it again to the top
    const index = recentPages.findIndex((url) => url === location.pathname);
    if (index === -1) {
      recentPages = [location.pathname, ...recentPages];
    } else {
      recentPages.splice(index, 1);
      recentPages = [location.pathname, ...recentPages];
    }
    // keep only the first 5 pages
    recentPages = recentPages.slice(0, 5);
    // save recent pages to local storage
    cacheService.set('recentPages', recentPages);
  }, [location]);

  i18n.on('languageChanged', setSelectedLanguage);

  return (
    <LoaderProvider>
      <ThemeProvider>
        <LocalizationProvider
          locale={selectedLanguage}
          dateAdapter={AdapterDayjs}
        >
          <SnackbarProvider
            maxSnack={6}
            autoHideDuration={4000}
            action={(snackBarKey) => (
              <SnackbarCloseButton snackBarKey={snackBarKey} />
            )}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <CssBaseline />
            {isTestEnv && <TestBanner />}
            <AuthProvider>{content}</AuthProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </LoaderProvider>
  );
}

export default App;
