import { Button, IconButton } from '@mui/material';
import {
  enqueueSnackbar,
  SnackbarOrigin,
  useSnackbar,
  VariantType
} from 'notistack';
import FileHelper from './FileHelper';
import CloseIcon from '@mui/icons-material/Close';

interface SnackbarOptions {
  variant?: VariantType;
  autoHideDuration?: number;
  anchorOrigin?: SnackbarOrigin;
}

const defaultOptions: SnackbarOptions = {
  autoHideDuration: 3000,
  anchorOrigin: { vertical: 'top', horizontal: 'right' }
};

const snackbar = {
  success: (message: string, options: SnackbarOptions = {}) => {
    enqueueSnackbar(message, {
      variant: 'success',
      ...defaultOptions,
      ...options
    });
  },
  error: (message: string, options: SnackbarOptions = {}) => {
    enqueueSnackbar(message, {
      variant: 'error',
      ...defaultOptions,
      ...options
    });
  },
  warning: (message: string, options: SnackbarOptions = {}) => {
    enqueueSnackbar(message, {
      variant: 'warning',
      ...defaultOptions,
      ...options
    });
  },
  info: (message: string, options: SnackbarOptions = {}) => {
    enqueueSnackbar(message, {
      variant: 'info',
      ...defaultOptions,
      ...options
    });
  },
  fileDownload: (onDownload: () => void) => {
    enqueueSnackbar('İndirme otomatik olarak başlamadıysa tıklayınız.', {
      variant: 'info',
      autoHideDuration: 5000,
      action: (snackbarKey) => (
        <>
          <Button color={'inherit'} onClick={onDownload}>
            İndir ⇩
          </Button>
          <SnackbarCloseButton snackBarKey={snackbarKey} />
        </>
      )
    });
  },
  errorAction: (
    message: string,
    actionMessage: string,
    onClick: () => void
  ) => {
    enqueueSnackbar(message, {
      variant: 'error',
      autoHideDuration: 5000,
      action: (snackbarKey) => (
        <>
          <Button color="inherit" onClick={onClick}>
            {actionMessage}
          </Button>
          <SnackbarCloseButton snackBarKey={snackbarKey} />
        </>
      )
    });
  }
};

export default snackbar;

export function SnackbarCloseButton({ snackBarKey }) {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton onClick={() => closeSnackbar(snackBarKey)}>
      <CloseIcon fontSize="medium" style={{ color: 'white' }} />
    </IconButton>
  );
}
